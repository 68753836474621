@import "variables";

.grid-auto-1fr,
.grid-1fr-auto,
.grid-1fr-1fr,
.grid-auto-auto {
  display: grid;
}

.grid-auto-1fr {
  grid-template-columns: auto 1fr;
  align-items: center;
}

.grid-1fr-auto {
  grid-template-columns: 1fr auto;
  align-items: center;
}

.grid-1fr-1fr {
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.grid-auto-auto {
  grid-template-columns: auto auto;
  align-items: center;
}

.grid-gap {
  grid-gap: $base-gap;
}

.grid-column-gap {
  column-gap: $base-gap;
}

.grid-row-gap {
  row-gap: $base-gap;
}