@use "@angular/material" as mat;

@include mat.core();

$material-theme: mat.define-theme((
  color: (
    theme-type: light,
    use-system-variables: true,
    system-variables-prefix: mat-theme
  ),
  typography: (use-system-variables: true),
  density: (scale: -1)
));

html {
  // System variables used by Material to determine colors
  --mat-theme-primary: white;
  --mat-theme-secondary-container: rgba(0, 0, 0, 0.04);
  --mat-theme-background: #fafafa;
  --mat-theme-surface: white;
  --mat-theme-surface-container: white;
  --mat-theme-inverse-surface: #616161;
  --mat-theme-inverse-on-surface: white;
  --mat-theme-outline: rgba(0, 0, 0, 0.12);
  --mat-theme-error: #e53935;
  --mat-theme-on-primary: black;
  --mat-theme-on-secondary-container: inherit;
  --mat-theme-on-background: rgba(0, 0, 0, 0.87);
  --mat-theme-on-surface: rgba(0, 0, 0, 0.87);
  --mat-theme-on-surface-variant: rgba(0, 0, 0, 0.87);
  --mat-theme-on-error-container: #e53935;

  @include mat.all-component-themes($material-theme);
  // Reset base styles (shapes, sizes, elevations, opacities etc.) to default values
  @include mat.all-component-bases(null);
}
