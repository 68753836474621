@import "variables";

@keyframes toast-slide-animation {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

.toast-container > .ngx-toastr {
  padding: 2 * $base-padding 2 * $base-padding 2 * $base-padding 4 * $base-padding + $icon-size;
  animation: toast-slide-animation 0.4s;
  animation-timing-function: $default-easing;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  opacity: 1;

  &:hover {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }

  &.toast-success,
  &.toast-info {
    background-color: $neutral-hue-1;
  }

  &.toast-error {
    background-color: $error-hue-1;
  }

  &.toast-warning {
    background-color: $warn-hue-1;
  }

  &.toast-success::after {
    content: "check";
  }

  &.toast-error::after {
    content: "error";
  }

  &.toast-info::after {
    content: "info";
  }

  &.toast-warning::after {
    content: "warning";
  }

  &.toast-success,
  &.toast-error,
  &.toast-info,
  &.toast-warning {
    background-image: none !important;
  }

  &.toast-success::after,
  &.toast-error::after,
  &.toast-info::after,
  &.toast-warning::after {
    color: white;
    direction: ltr;
    display: inline-block;
    font-family: "Material Symbols";
    font-feature-settings: "liga";
    font-weight: normal;
    font-size: $base-height;
    font-style: normal;
    grid-area: toast-icon;
    height: $base-height;
    left: 2 * $base-padding;
    letter-spacing: normal;
    line-height: 1;
    position: absolute;
    text-rendering: optimizeLegibility;
    text-transform: none;
    top: calc(50% - 20px);
    white-space: nowrap;
    width: $base-height;
    word-wrap: normal;
  }

  .toast-title {
    margin-bottom: 0.5em;
  }

  .toast-title,
  .toast-message {
    padding-left: 2 * $base-padding;
  }
}
