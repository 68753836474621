@import "variables";

mat-slider[color="accent"] {
  --mdc-slider-inactive-track-color: #{$tertiary-color};
  --mdc-slider-active-track-color: #{$tertiary-color};
  --mdc-slider-handle-color: #{$tertiary-color};
  --mdc-slider-hover-handle-color: #{$tertiary-color};
  --mdc-slider-focus-handle-color: #{$tertiary-color};
  --mat-slider-hover-state-layer-color: rgba(#{$tertiary-color-rgb}, 0.05);
  --mat-slider-focus-state-layer-color: rgba(#{$tertiary-color-rgb}, 0.2);
}
