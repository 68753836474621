mat-radio-button .mdc-form-field label {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.mdc-radio__outer-circle,
.mdc-radio__inner-circle {
  --mdc-radio-selected-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #fff;
  --mdc-radio-selected-hover-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-selected-pressed-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
}
