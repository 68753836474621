$neutral-hue-1: #000000;
$info-hue-1: #039be5;
$tip-hue-1: #01b8aa;
$prompt-hue-1: #f2cf00;
$error-hue-1: #fd625e;
$warn-hue-1: #fe9666;

$secondary-color: #005b96;
$tertiary-color: #ff8522;
$secondary-color-rgb: 0, 91, 150;
$tertiary-color-rgb: 255, 133, 34;
$outline-variant: rgba(0, 0, 0, 0.54);
$hover: rgba(102, 108, 120, 0.1);

$font-family: "Quicksand", "Segoe UI", "Helvetica Neue", sans-serif;
$font-family-mono: "Roboto Mono", Consolas, Monaco, "Andale Mono", "Courier New", "Ubuntu Mono", monospace;
$font-size: 16px;

$base-size: 8px;
$base-padding: $base-size;
$base-gap: $base-size;
$base-height: 5 * $base-size;
$base-width: 5 * $base-size;
$icon-size: 3 * $base-size;

$default-easing: cubic-bezier(0, 0, 0.2, 1); // Material deceleration curve
