@import "variables";

.mat-headline-5 {
  font: 400 24px/32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-headline-6 {
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 2 * $base-padding 0;
}

.mat-subtitle-1 {
  font: 400 16px/28px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 2 * $base-padding 0;
}

.mat-mdc-button,
.mat-mdc-card,
.mat-mdc-unelevated-button,
.mat-mdc-form-field,
.mat-headline-5,
.mat-mdc-menu-item,
.mat-mdc-option,
.mat-mdc-select,
.mat-toolbar {
  font-family: $font-family;
  font-weight: normal;
}
