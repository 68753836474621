@import "variables";

mat-select {
  --mat-select-enabled-arrow-color: #{$outline-variant};

  .mat-mdc-select-value {
    padding-right: $base-padding;
  }
}

mat-option {
  --mat-option-hover-state-layer-color: var(--mat-theme-secondary-container);
}

.mat-primary mat-option {
  --mat-option-label-text-color: black;
  min-height: $base-height;

  mat-icon,
  i {
    color: var(--icon-hue);
    margin-right: $base-padding;
  }

  .mdc-list-item__primary-text {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
