@import "variables";

mat-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-hover-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-pressed-icon-color: #{$secondary-color};
  --mdc-checkbox-selected-focus-state-layer-color: rgba(#{$secondary-color-rgb}, 0.5);
  --mdc-checkbox-selected-pressed-state-layer-color: rgba(#{$secondary-color-rgb}, 0.5);

  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-unselected-icon-color: #fff;
  --mdc-checkbox-unselected-pressed-icon-color: #fff;
  --mdc-checkbox-unselected-focus-state-layer-color: rgba(#{$secondary-color-rgb}, 0.5);
  --mdc-checkbox-unselected-pressed-state-layer-color: rgba(#{$secondary-color-rgb}, 0.5);

  label > span {
    color: #fff;
  }
}
