@import "variables";

button {
  --mdc-filled-button-container-height: 32px;
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, .12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, .38);

  --mdc-icon-button-state-layer-size: 44px;
  --mdc-icon-button-icon-color: inherit;
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-hover-state-layer-opacity: 0.04;

  &[color="accent"] {
    --mdc-filled-button-container-color: #{$tertiary-color};
    --mdc-filled-button-label-text-color: white;
  }

  &:not(.mat-mdc-icon-button) mat-icon {
    margin-right: 0.5em;
  }

  .mdc-button__label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
